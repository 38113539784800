<template>
  <div class="auth-logo">
    <img loading="lazy" src="@/assets/images/logo.png" alt="logo" />
  </div>
</template>
<style lang="scss" scoped>
img {
  width: 232px;
  height: 160px;
  @media screen and (max-width: 250px) {
    width: 100%;
    height: auto;
  }
}
</style>
