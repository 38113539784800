<template>
  <div class="account_info" @click="$emit('openAccountActions')">
    <img loading="lazy" :src="avatar" alt="avatar" />
    <div class="account_info-content">
      <p class="account_info-content-name">{{ userName }}</p>
      <p class="account_info-content-id">ID: {{ localId }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputComponent",
  props: {
    localId: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.account_info {
  position: relative;
  background-color: rgba(#000000, 0.3);
  padding: 10px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    &:hover {
      background-color: rgba(#000, 0.1);
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    @media screen and (max-width: 374px) {
      width: 20px;
      height: 20px;
    }
  }
  &-content {
    padding: 3px 0;
    &-name {
      color: var(--text-main);
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 14px;
      @media screen and (max-width: 374px) {
        font-size: 10px;
        line-height: 10px;
      }
    }
    &-id {
      color: var(--text-secondary);
      font-size: 12px;
      line-height: 12px;
      @media screen and (max-width: 374px) {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
</style>
