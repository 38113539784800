import { createStore } from "vuex";
import login from "./login";
import email from "./email";
import umoney from "./umoney";

export default createStore({
  modules: {
    login,
    email,
    umoney,
  },
});
