<template>
  <AuthForm />
</template>
<script>
import AuthForm from "@/components/AuthForm.vue";
export default {
  name: "AuthPage",
  components: {
    AuthForm,
  },
};
</script>
