<template>
  <div class="auth">
    <AuthLogo />
    <div class="auth-form">
      <h2>Укажите свой аккаунт</h2>
      <p>Найдите его по игровому ID</p>
      <Input
        v-model="ID"
        placeholder="ID"
        :is-error="isError"
        :error-msg="errorMsg"
      />
      <Button text="Войти" :is-disabled="isDisabled" @click="handleLogin(ID)" />
    </div>
    <a
      v-if="localId"
      class="auth-account"
      href="#"
      @click="handleLogin(localId)"
    >
      <AccountInfo
        :local-id="localId"
        :user-name="userName"
        :avatar="`${VUE_APP_API}User/GetStoreUserAvatar?PersonalId=${localId}`"
      />
      <img
        loading="lazy"
        class="arrow"
        src="@/assets/images/arrow.svg"
        alt="arrow"
      />
    </a>
    <AndroidDownload link="https://disk.yandex.ru/d/LVXSH2OdABSa_Q" />
  </div>
</template>
<script>
import Input from "@/components/simple/Input.vue";
import Button from "@/components/simple/Button.vue";
import AccountInfo from "@/components/simple/AccountInfo.vue";
import AuthLogo from "@/components/simple/AuthLogo.vue";
import AndroidDownload from "./simple/AndroidDownload.vue";
import { mapActions, mapState } from "vuex";
import { push } from "notivue";
export default {
  name: "AuthFormComponent",
  components: {
    Input,
    Button,
    AccountInfo,
    AuthLogo,
    AndroidDownload,
  },
  data() {
    return {
      ID: "",
      isError: false,
      errorMsg: "",
      localId: null,
      VUE_APP_API: null,
    };
  },
  computed: {
    ...mapState({
      userName: (state) => state.login.userName,
    }),
    isDisabled() {
      return this.ID.length == 0;
    },
  },
  mounted() {
    this.VUE_APP_API = process.env.VUE_APP_API;
    const localAccountId = localStorage.getItem("accountId");
    if (localAccountId) {
      this.localId = localAccountId;
      this.getUserName(localAccountId);
    }
  },
  methods: {
    ...mapActions(["login", "getUserName"]),
    async handleLogin(id) {
      const response = await this.login(id);
      this.isError = !response.success;
      if (!response.success) {
        this.errorMsg = response.message;
        push.error(this.errorMsg);
      } else {
        this.errorMsg = "";
        this.$router.push("/home");
      }
    },
  },
};
</script>
<styles lang="scss" scoped>
.auth {
  position: relative;
  width: 730px;
  margin: 105px auto 0;
  background-color: rgba(#000000, 0.3);
  padding: 120px 0 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--text-hover);
  border-radius: 6px;
  @media screen and (max-width: 1800px) {
    position: static;
    transform: none;
    margin: 105px auto 28px;
    padding: 135px 0 27px;
  }
  @media screen and (max-width: 768px) {
    width: 355px;
  }
  @media screen and (max-width: 374px) {
    width: 95%;
    max-width: 295px;
    padding: 135px 10px 27px;
  }
  @media screen and (max-width: 250px) {
    padding: 10px;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 374px) {
      text-align: center;
    }
    h2 {
      font-size: 24px;
      line-height: 24px;
      color: var(--text-main);
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      line-height: 16px;
      color: var(--text-secondary);
      margin-bottom: 38px;
    }
  }
  &-account {
    display: flex;
    align-items: center;
    width: 380px;
    justify-content: space-between;
    background-color: rgba(#000000, 0.3);
    padding-right: 20px;
    border-radius: 4px;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      &:hover {
        background-color: rgba(#000000, 0.1);
      }
    }
    @media screen and (max-width: 768px) {
      width: 295px;
    }
    @media screen and (max-width: 374px) {
      width: 100%;
    }
    .arrow {
      width: 14px;
      height: 12px;
      opacity: 0.3;
    }
    @media screen and (min-width: 768px) {
      &:hover .arrow {
        opacity: 1;
      }
    }
  }
  .yellow {
    width: 148px;
    font-size: 16px !important;
    line-height: 16px !important;
    &:disabled {
      background-color: rgba(#ffd645, 0.2) !important;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.auth-logo {
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1800px) {
    top: 33px;
  }
  @media screen and (max-width: 250px) {
    position: relative;
    transform: translateY(-80%) translateX(-50%);
    margin-bottom: -60px;
  }
}
.auth-account .account_info {
  background-color: unset !important;
  padding: 10px 20px;
  img {
    margin-right: 14px;
  }
}
.auth-account {
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
}
</styles>
