<template>
  <div class="android">
    <label>Скачать для Android</label>
    <a class="android-link" :href="link" target="_blank" download>
      <picture>
        <source
          media="(min-width: 768px)"
          srcset="@/assets/images/android_download.png"
        />
        <source
          media="(max-width: 768px)"
          srcset="@/assets/images/android_download_mobile.png 768x"
        />
        <img
          loading="lazy"
          src="@/assets/images/android_download.png"
          alt="android"
        />
      </picture>
    </a>
  </div>
</template>

<script>
export default {
  name: "ScamdoffAndroid",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.android {
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    font-style: 16px;
    line-height: 16px;
    color: #63657c;
    margin-bottom: 4px;
    @media screen and (max-width: 768px) {
      margin-bottom: 11px;
    }
  }
  &-link {
    cursor: pointer;
    img {
      width: 380px;
      height: 60px;
      @media screen and (max-width: 768px) {
        width: 295px;
        max-width: 100%;
        height: 48px;
      }
    }
  }
}
</style>
