<template>
  <div class="checkbox">
    <input
      id="checkbox"
      :value="modelValue"
      type="checkbox"
      @input="$emit('update:modelValue', !modelValue)"
    />
    <label for="checkbox">
      <slot name="label"></slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "CheckboxComponent",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  position: relative;
  input {
    position: absolute;
    left: -1000px;
    margin: 0;
    width: 0;
    height: 0;
  }
  label {
    cursor: pointer;
    position: relative;
    display: block;
    width: calc(100% - 38px);
    margin-left: 38px;
    &::before {
      content: "";
      position: absolute;
      width: 22px;
      height: 22px;
      top: 0;
      left: -38px;
      border: 2px solid var(--text-hover);
      border-radius: 2px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 11px;
      height: 6px;
      top: 5px;
      left: -31.5px;
      border-width: 2px 2px 0 0;
      border-style: solid;
      border-radius: 1px;
      border-color: var(--text-main);
      transform: rotate(135deg);
      opacity: 0;
    }
  }
  input:checked + label {
    &::before {
      background-color: var(--text-hover);
    }
    &::after {
      opacity: 1;
    }
  }
}
</style>
