<template>
  <div class="bp">
    <div class="bp-golds">
      <div
        v-for="card in goldPass"
        :key="card.lvl + card.cost"
        class="bp-golds-card"
      >
        <picture>
          <source
            media="(min-width: 768px)"
            srcset="@/assets/images/gold_pass.png"
          />
          <source
            media="(max-width: 768px)"
            srcset="@/assets/images/gold_pass_mobile.png"
          />
          <img
            loading="lazy"
            class="bp-golds-card-bg"
            src="@/assets/images/gold_pass.png"
            alt="background"
          />
        </picture>
        <div class="bp-golds-card-left">
          <h2>Gold Pass</h2>
          <p v-if="card.lvl.length">+ {{ card.lvl }} уровней</p>
        </div>
        <div class="bp-golds-card-right">
          <img loading="lazy" src="@/assets/images/four_gold.png" alt="money" />
          <Button
            :text="card.cost"
            :is-disabled="false"
            @click="$emit('openEmail')"
          />
        </div>
      </div>
    </div>
    <div class="bp-cards">
      <div v-for="star in stars" :key="star.cost" class="bp-cards-card">
        <p v-if="star.discont">{{ star.discont }}</p>
        <h2>{{ star.label }}</h2>
        <div class="star-box">
          <img loading="lazy" :src="imgSrc(star.star)" alt="star" />
        </div>
        <Button
          :text="star.cost"
          :is-disabled="false"
          @click="$emit('openEmail')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Button from "./simple/Button.vue";
export default {
  name: "BattlePassComponent",
  components: {
    Button,
  },
  data() {
    return {
      goldPass: [
        {
          lvl: "",
          cost: "600 ₽",
        },
        {
          lvl: "25",
          cost: "1300 ₽",
        },
      ],
      stars: [
        {
          label: "1",
          cost: "100 ₽",
          star: "silver",
        },
        {
          label: "10",
          cost: "300 ₽",
          star: "gold",
        },
        {
          label: "25",
          cost: "500 ₽",
          discont: "-50 %",
          star: "brill",
        },
        {
          label: "75",
          cost: "1000 ₽",
          star: "max",
        },
      ],
    };
  },
  methods: {
    imgSrc(star) {
      return require(`@/assets/images/star_${star}.png`);
    },
  },
};
</script>
<style lang="scss" scoped>
.bp {
  &-golds {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;
    &-card {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 518px;
      height: 160px;
      border: 1px solid;
      padding: 17px 19px 19px 23px;
      border-radius: 4px;

      border-image-source: linear-gradient(
        180deg,
        rgba(254, 86, 230, 0.42) 0%,
        rgba(106, 123, 253, 0.42) 100%
      );
      background: linear-gradient(180deg, #1e1b25 0%, #181e2e 100%),
        linear-gradient(
          180deg,
          rgba(254, 86, 230, 0.42) 0%,
          rgba(106, 123, 253, 0.42) 100%
        );
      @media screen and (max-width: 1150px) {
        width: calc(50% - 6px);
      }
      @media screen and (max-width: 909px) {
        width: 518px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        max-width: 315px;
        height: 215px;
        padding: 14px 16px 16px;
      }
      picture {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      &-bg {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      &-left {
        position: relative;
        z-index: 15;
        width: 138px;
        @media screen and (max-width: 768px) {
          margin-bottom: 12px;
        }
        h2 {
          font-size: 48px;
          font-weight: 900;
          line-height: 48px;
          color: var(--text-yellow);
          text-shadow: 0px 0px 4px #ff6b00;
          text-transform: uppercase;
          margin-bottom: 9px;
        }
        p {
          color: var(--text-main);
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          text-shadow: 0px 0px 4px #000;
        }
      }
      &-right {
        position: relative;
        display: flex;
        align-items: flex-end;
        z-index: 15;
        width: 223px;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        img {
          position: absolute;
          bottom: 0;
          right: 50px;
          z-index: 14;
          width: 123px;
          height: 123px;
          @media screen and (max-width: 768px) {
            top: -132px;
            right: 0;
          }
          @media screen and (max-width: 374px) {
            display: none;
          }
        }
        .yellow {
          position: relative;
          z-index: 15;
        }
      }
    }
  }
  &-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    @media screen and (max-width: 768px) {
      gap: 10px;
    }
    &-card {
      width: 253px;
      height: 293px;
      padding: 30px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      background: linear-gradient(180deg, #1e1b25 0%, #181e2e 100%);
      @media screen and (max-width: 1150px) {
        width: calc(50% - 6px);
      }
      @media screen and (max-width: 768px) {
        width: calc(50% - 5px);
        height: 267px;
      }
      @media screen and (max-width: 374px) {
        width: 100%;
      }
      h2 {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        margin-bottom: 27px;
        color: var(--text-yellow);
        text-shadow: 0px 0px 10px #ff6b00;
      }
      .star-box {
        width: 130px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      img {
        width: 120px;
        height: auto;
        @media screen and (max-width: 768px) {
          width: 96px;
          margin-bottom: 16px;
        }
      }
      p {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--text-main);
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        padding: 5px 6px;
        background-color: var(--text-hover);
        border-radius: 0 4px;
      }
    }
  }
}
.yellow {
  width: 100%;
  margin-bottom: 0;
  background-color: var(--btn-bg-main);
}
</style>
