<template>
  <footer>
    <div class="social">
      <a href="https://t.me/scamdoff" target="_blank">
        <img loading="lazy" src="@/assets/images/telegram.svg" alt="telegram" />
      </a>
      <a href="https://discord.gg/ummH7Jgujm" target="_blank">
        <img loading="lazy" src="@/assets/images/discord.svg" alt="discord" />
      </a>
    </div>
    <div class="oferta">
      <router-link class="decoration" to="oferta">Договор оферта</router-link>
    </div>
    <div>
      <router-link class="decoration" to="policy"
        >Политика конфиденциальности</router-link
      >
    </div>
  </footer>
</template>
<script>
export default {
  name: "FooterComponent",
};
</script>
<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 4px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  @media screen and (max-height: 1000px) {
    position: static;
  }
  @media screen and (max-width: 1800px) {
    position: static;
  }
  @media screen and (max-width: 374px) {
    text-align: center;
  }
  img {
    opacity: 0.3;
    width: 28px;
    height: 20px;
  }
  @media screen and (min-width: 768px) {
    img:hover {
      opacity: 1;
    }
  }
  .social {
    margin-bottom: 34px;
    @media screen and (max-width: 768px) {
      margin-bottom: 31px;
    }
    a:first-child {
      margin-right: 33px;
    }
  }
  .oferta {
    margin-bottom: 18px;
  }
}
</style>
