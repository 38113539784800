<template>
  <button class="btn-svg" @click="$emit('clickEmit')">
    <slot></slot>
    <span>{{ text }}</span>
  </button>
</template>
<script>
export default {
  name: "ButtonSvg",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-svg {
  display: flex;
  align-items: center;
  ::v-deep img {
    width: 16px;
    height: 12px;
    margin-right: 16px;
  }
  span {
    font-size: 16px;
    line-height: 16px;
    color: var(--text-main);
    @media screen and (max-width: 374px) {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    &:hover span {
      color: var(--text-hover);
    }
  }
}
</style>
