<template>
  <div class="input">
    <input
      :value="modelValue"
      :placeholder="placeholder"
      :type="type"
      @blur="$emit('blur')"
      @input="(e) => $emit('update:modelValue', e.target.value)"
    />
    <button
      v-if="isCross"
      class="cross"
      @click="$emit('update:modelValue', '')"
    >
      <img loading="lazy" src="@/assets/images/cross.svg" alt="cross" />
    </button>
    <div class="box--error">
      <Transition name="slide-fade">
        <label v-show="isError" class="label--error">{{ errorMsg }}</label>
      </Transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputComponent",
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    modelValue: [String, Number],
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    isCross: {
      type: Boolean,
      default: true,
    },
    errorMsg: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.input {
  position: relative;
  margin-bottom: 26px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1px;
  }
  input {
    border-radius: 4px;
    width: 380px;
    height: 64px;
    background-color: rgba(#6a7bfd, 0.1);
    border: 1px solid var(--text-hover);
    padding: 22px 62px 22px 20px;
    color: var(--text-main);
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
    -moz-appearance: textfield;

    @media screen and (max-width: 768px) {
      width: 295px;
      margin-bottom: 1px;
    }
    @media screen and (max-width: 374px) {
      width: 100%;
    }

    &::placeholder {
      position: absolute;
      top: 20px;
      font-size: 20px;
      line-height: 20px;
      color: var(--text-secondary);
    }
    &:focus {
      color: var(--btn-text-main);
      background-color: var(--text-main);
    }
    &:focus + .cross {
      opacity: 1;
    }

    &.error {
      border: 1px solid var(--error);
    }
    &.error + .cross {
      opacity: 1;
    }
  }
  .label--error {
    display: block;
    font-size: 14px;
    line-height: 14px;
    color: var(--error);
  }
  .cross {
    position: absolute;
    top: 26px;
    right: 26px;
    opacity: 0.3;
    @media screen and (max-width: 768px) {
      top: 24px;
    }
    img {
      width: 12px;
      height: 12px;
    }
    @media screen and (min-width: 768px) {
      &:hover {
        opacity: 1;
      }
    }
  }
  .box--error {
    min-height: 14px;
    @media screen and (max-width: 768px) {
      width: 295px;
    }
    @media screen and (max-width: 374px) {
      width: 100%;
    }
  }
}
</style>
