<template>
  <div class="tabs">
    <div
      :class="['tabs-tab', { 'tabs-tab--active': activeTab == 'Currency' }]"
      @click="changeTab('Currency')"
    >
      <p>Валюта</p>
      <div
        :class="[
          'borders',
          { 'borders-is': activeTab == 'Currency' },
          {
            'borders-next': activeTab == 'BattlePass',
          },
        ]"
      >
        <div class="angle-border"></div>
        <div class="bottom-border"></div>
        <div class="angle-next-border"></div>
      </div>
    </div>
    <!-- <div
      :class="[
        'tabs-tab tab-bp',
        { 'tabs-tab--active': activeTab == 'BattlePass' },
      ]"
      @click="changeTab('BattlePass')"
    >
      <p>Battle Pass</p>
      <div :class="['borders', { 'borders-is': activeTab == 'BattlePass' }]">
        <div class="angle-border"></div>
        <div class="bottom-border"></div>
        <div class="angle-next-border"></div>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "TabsComponent",
  props: {
    activeTab: {
      type: String,
      default: "Currency",
    },
  },
  data() {
    return {
      tabs: [
        {
          id: "Currency",
          title: "Валюта",
        },
        {
          id: "BattlePass",
          title: "Battle Pass",
        },
      ],
    };
  },
  computed: {
    isCurrencyTab() {
      return this.activeTab == "Currency";
    },
    isBattlePassTab() {
      return this.activeTab == "BattlePass";
    },
  },
  methods: {
    changeTab(tab) {
      this.$emit("changeTab", "");
      setTimeout(() => this.$emit("changeTab", tab), 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  display: flex;
  position: relative;
  @media screen and (max-width: 480px) {
    .tab-bp .angle-border {
      display: none;
    }
  }
  .borders {
    .bottom-border {
      position: absolute;
      z-index: 10;
      bottom: -1px;
      left: 0;
      height: 2px;
      width: 100%;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: rgb(18, 20, 27);
      }
    }
    .angle-border {
      opacity: 0;
      position: absolute;
      z-index: 11;
      bottom: -1px;
      right: -8px;
      width: 8px;
      height: 8px;
      overflow: hidden;
      box-sizing: border-box;
      &::before {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-style: solid;
        border-width: 0px;
        border-color: rgb(18, 20, 27);
        transform: rotate(230deg);
      }
    }
    .angle-next-border {
      opacity: 0;
      position: absolute;
      z-index: 11;
      bottom: -2px;
      right: -5px;
      width: 8px;
      height: 8px;
      overflow: hidden;
      box-sizing: border-box;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-width: 0px;
        border-style: solid;
        border-color: rgb(18, 20, 27);
        box-shadow: -8px 5px rgb(18, 20, 27);
        transform: rotate(230deg) translateX(50%) translateY(-50%);
      }
    }
  }
  .borders-is {
    .angle-border {
      opacity: 1;
    }
    .angle-border::before {
      border-width: 1px 0 0;
      border-radius: 100%;
      border-color: var(--text-hover);
      box-shadow: 0px -4px rgb(18, 20, 27);
    }
    .bottom-border::before {
      height: 2px;
    }
  }
  .borders-next {
    .angle-next-border {
      opacity: 1;
    }
    .angle-next-border::before {
      border-width: 0 0 0 1px;
      border-radius: 100%;
      border-color: var(--text-hover);
      opacity: 1;
    }
  }
  &-tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 60px;
    background-color: rgba(#000000, 0.2);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--text-hover);
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: rgba(#000000, 0.2);
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    @media screen and (max-width: 480px) {
      width: 50%;
    }
    @media screen and (min-width: 768px) {
      &:hover {
        background-color: rgba(#000000, 0.1);
      }
    }
    &--active {
      border-color: var(--text-hover);
      background-color: rgba(#000000, 0.3);
      color: var(--text-main);
    }
  }
}
</style>
