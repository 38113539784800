<template>
  <div class="currency">
    <div class="currency-cards">
      <div
        v-for="currency in currencies"
        :key="currency.cost"
        class="currency-cards-card"
      >
        <p v-if="currency.discont">{{ currency.discont }}</p>
        <h2>{{ currency.label }}</h2>
        <span>Токенов, игровая валюта в игре Scamdoff</span>
        <img
          v-if="currency.cost == '25'"
          loading="lazy"
          src="@/assets/images/one_gold.png"
          alt="money"
        />
        <img
          v-if="currency.cost == '125'"
          loading="lazy"
          src="@/assets/images/two_gold.png"
          alt="money"
        />
        <img
          v-if="currency.cost == '250'"
          loading="lazy"
          src="@/assets/images/three_gold.png"
          alt="money"
        />
        <img
          v-if="currency.cost == '750'"
          loading="lazy"
          src="@/assets/images/four_gold.png"
          alt="money"
        />
        <Button
          :text="`${currency.cost} ₽`"
          :is-disabled="false"
          @click="
            $emit('openEmail', {
              money: currency.cost,
              hard: currency.label,
            })
          "
        />
      </div>
      <div class="currency-cards-card responsive">
        <h2>Своя сумма</h2>
        <span class="mb-37">Токенов, игровая валюта в игре Scamdoff</span>
        <Input
          :model-value="hard"
          type="Number"
          placeholder="0"
          :is-error="false"
          @update:modelValue="handleChange"
        />
        <Button
          :text="`${summ || '0'} ₽`"
          :is-disabled="summ.length == 0"
          @click="handleDonate"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Button from "./simple/Button.vue";
import Input from "./simple/Input.vue";
import { push } from "notivue";
export default {
  name: "CurrencyComponent",
  components: {
    Button,
    Input,
  },
  data() {
    return {
      currencies: [
        {
          label: "100",
          cost: "25",
        },
        {
          label: "500",
          cost: "125",
        },
        {
          label: "1000",
          cost: "250",
        },
        {
          label: "3000",
          cost: "750",
        },
      ],
      summ: "",
      hard: "",
    };
  },
  watch: {
    hard() {
      this.summ = this.hard / 4;
    },
  },
  methods: {
    handleDonate() {
      if (this.summ <= 14000 && this.summ >= 25) {
        this.$emit("openEmail", {
          money: this.summ,
          hard: this.hard,
        });
      } else if (this.summ > 14000) {
        push.error("Сумма не должна превышать 14 000 рублей.");
      } else {
        push.error("Сумма должна превышать 25 рублей.");
      }
    },
    handleChange(val) {
      this.hard = Math.round(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.currency {
  &-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 11px;
    @media screen and (max-width: 768px) {
      gap: 10px;
    }
    &-card {
      width: 200px;
      background: linear-gradient(180deg, #29251a 0%, #33150f 100%);
      padding: 30px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-radius: 4px;
      @media screen and (max-width: 768px) {
        width: calc(50% - 5px);
      }
      @media screen and (max-width: 374px) {
        width: 100%;
      }
      &::v-deep input {
        border: unset;
      }
      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        margin-bottom: 7px;
        color: var(--text-yellow);
        text-shadow: 0px 0px 10px #ff6b00;
        text-transform: uppercase;
      }
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        text-align: center;
        color: var(--text-yellow);
        margin-bottom: 27px;
      }
      img {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
      }
      p {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--text-main);
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        padding: 5px 6px;
        background-color: var(--text-hover);
        border-radius: 0 4px;
      }
    }
    .responsive {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      h2 {
        line-height: 24px;
      }
      span {
        margin-bottom: 37px;
        @media screen and (max-width: 768px) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .yellow {
    width: 100%;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      width: 327px;
      max-width: 100%;
    }
    @media screen and (max-width: 374px) {
      width: 100%;
    }
  }
  .yellow:not(:disabled):not(:hover) {
    background-color: var(--btn-bg-main);
  }
}
::v-deep .input {
  margin-bottom: 0;
  input {
    width: 100%;
    padding: 22px 42px 22px 20px;
    margin-bottom: 6px;
  }
}
</style>
