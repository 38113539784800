<template>
  <div ref="modal" class="modal">
    <div class="modal-drop" @click="$emit('close')"></div>
    <div class="modal-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalComponent",
  mounted() {
    document.onkeyup = (e) => {
      if (e.which == 27) {
        this.$emit("close");
      }
    };
    const body = document.querySelector("body");
    body.classList.add("scroll--off");
  },
  unmounted() {
    const body = document.querySelector("body");
    body.classList.remove("scroll--off");
    document.onkeyup = () => {};
  },
};
</script>
<style lang="scss" scoped>
.modal {
  transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  &-drop {
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.4);
  }
  &-content {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
