<template>
  <div class="home">
    <div class="home-header">
      <AuthLogo class="web" />
      <img
        loading="lazy"
        class="mobile logo--mobile"
        src="@/assets/images/logo_mobile.png"
        alt="logo"
      />
      <div class="account">
        <AccountInfo
          :local-id="accountId"
          :user-name="userName"
          :avatar="`${VUE_APP_API}User/GetStoreUserAvatar?PersonalId=${accountId}`"
        />
        <router-link to="/"> Сменить аккаунт </router-link>
      </div>
    </div>
    <div>
      <Tabs :active-tab="activeTab" @changeTab="(tab) => (activeTab = tab)" />
      <div :class="['card', { 'card--border': activeTab !== 'Currency' }]">
        <Transition name="slide-tab">
          <component
            :is="activeTab"
            @openEmail="
              (cost) =>
                $emit('openModal', {
                  nameModal: 'Email',
                  modalData: cost,
                })
            "
          />
        </Transition>
      </div>
    </div>
  </div>
</template>
<script>
import AuthLogo from "@/components/simple/AuthLogo.vue";
import AccountInfo from "@/components/simple/AccountInfo.vue";
import Currency from "@/components/Currency.vue";
import BattlePass from "@/components/BattlePass.vue";
import Tabs from "@/components/Tabs.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { push } from "notivue";
export default {
  name: "HomePage",
  components: {
    AuthLogo,
    AccountInfo,
    Currency,
    BattlePass,
    Tabs,
  },
  data() {
    return {
      activeTab: "Currency",
      VUE_APP_API: null,
    };
  },
  computed: {
    ...mapState({
      accountId: (state) => state.login.accountId,
      userName: (state) => state.login.userName,
    }),
  },
  async mounted() {
    this.VUE_APP_API = process.env.VUE_APP_API;
    this.getUserName(this.accountId);
    const isSuccess = this.$route.query.success;
    if (isSuccess) {
      const response = await this.checkStatusPay(this.accountId);
      push[response.type](response.message);
    }
  },
  methods: {
    ...mapActions(["getUserName", "checkStatusPay"]),
    ...mapMutations(["buyCurrency"]),
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 95%;
  max-width: 1110px;
  margin: 20px auto 0;
  &-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 37px;
    @media screen and (max-width: 1200px) {
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 160px;
      max-width: 335px;
      margin: 0 auto 10px;
    }
    .links {
      display: flex;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 142px;
        height: 60px;
        color: var(--text-main);
        background-color: rgba(#000000, 0.3);
        border-radius: 4px;
        @media screen and (min-width: 768px) {
          &:hover {
            color: var(--text-hover);
            background-color: rgba(#000000, 0.1);
          }
        }
      }
    }
    .menu {
      height: 16px;
      padding: 14px 0 15px 10px;
    }
    .logo--mobile {
      width: 335px;
      max-width: 100%;
      height: 100%;
      max-height: 36px;
      margin-bottom: 9px;
    }
  }
}
.auth-logo {
  position: static;
  transform: none;
}
.card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 30px;
  background-color: rgba(#000000, 0.3);
  border: 1px solid var(--text-hover);
  border-radius: 0 8px 8px 8px;
  color: var(--text-main);
  @media screen and (max-width: 1800px) {
    padding: 20px;
    margin-bottom: 49px;
  }
}
.card--border {
  border-radius: 8px 0 8px 8px;
}

.account {
  display: flex;
  align-items: center;
  width: 335px;
  height: 60px;
  justify-content: space-between;
  background-color: rgba(#000000, 0.3);
  padding-right: 20px;
  border-radius: 4px;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    &:hover {
      background-color: rgba(#000000, 0.1);
    }
  }
  .account_info {
    background-color: unset !important;
  }
  a {
    color: #ff4ce3;
    font-size: 12px;
    line-height: 12px;
    @media screen and (max-width: 374px) {
      font-size: 10px;
      line-height: 10px;
    }
  }
}
.web {
  margin-right: 105px;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1200px) {
  .mobile {
    display: flex;
  }
  .web {
    display: none;
  }
}
</style>
