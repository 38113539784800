import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/main.scss";
import { createNotivue } from "notivue";
import axios from "axios";

const app = createApp(App);

const notivue = createNotivue({
  enqueue: true,
  limit: 2,
  notifications: {
    global: {
      duration: 3000,
    },
  },
});
axios.defaults.baseURL = process.env.VUE_APP_API;

app.use(notivue);
app.use(store);
app.use(router);

app.mount("#app");
