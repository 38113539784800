export default {
  state: {
    email: "",
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
      const max_age = 3600 * 24 * 30;
      document.cookie = `email=${email}; max-age=${max_age};`;
    },
  },
  actions: {
    updateEmail({ commit }, email) {
      commit("setEmail", email);
    },
    getEmail({ commit }) {
      const value = `; ${document.cookie}`;
      const parts = value.split("; email=");
      const email = parts.length === 2 ? parts.pop().split(";").shift() : "";
      commit("setEmail", email);
    },
  },
};
