<template>
  <div id="app">
    <main>
      <router-view @openModal="(data) => openModal(data)" />
    </main>
    <Footer />
    <Modal v-if="showModal" @close="closeModal">
      <component :is="nameModal" :data="modalData" @close="closeModal" />
    </Modal>
    <Notivue v-slot="item">
      <NotivueSwipe :item="item">
        <Notifications :item="item" />
      </NotivueSwipe>
    </Notivue>
    <BGMain />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import BGMain from "@/components/BGMain.vue";
import Modal from "@/components/Modal.vue";
import Email from "./components/modals/Email.vue";
import { Notivue, Notifications, NotivueSwipe } from "notivue";
export default {
  name: "AppPage",
  components: {
    Footer,
    BGMain,
    Modal,
    Email,
    Notifications,
    Notivue,
    NotivueSwipe,
  },
  data() {
    return {
      showModal: false,
      nameModal: "",
      modalData: null,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal(data) {
      const { nameModal, modalData } = data;
      this.showModal = true;
      this.nameModal = nameModal;
      this.modalData = modalData;
    },
  },
};
</script>
<style lang="scss">
@import "notivue/notifications.css";
@import "notivue/animations.css";
main {
  padding-bottom: 150px;
  @media screen and (max-width: 1800px) {
    padding: 0;
  }
}
</style>
