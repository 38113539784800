<template>
  <div class="policy">
    <ButtonBack />
    <AuthLogo />
    <div class="policy-content">
      <h1>Политика конфиденциальности</h1>
      <label>Редакция № 1 от 28.12.2023 г.</label>
      <p>
        Документ определяет политику Оператора в области обработки и защиты
        персональных данных (далее – Политика).
      </p>
      <p>Данные Оператора указаны в реквизитах Политики.</p>
      <h2>Термины и определения</h2>
      <div class="termin">
        <p>Сайт</p>
        <p>
          сайт по адресу
          <a href="http://scamdoff.store" target="_blank"
            >http://scamdoff.store</a
          >, предназначенный для приобретения Дополнительного функционала в Игре
        </p>
      </div>
      <div class="termin">
        <p>Игра</p>
        <p>
          игра для мобильных устройств и персональных компьютеров по названием
          “Scamdoff”, которая позволяет пользователям играть в разные виды
          мини-игр, общаться друг с другом и выигрывать игровую валюту
        </p>
      </div>
      <div class="termin">
        <p>Субъект</p>
        <p>
          дееспособное физическое лицо, чьи Персональные данные обрабатывает
          Оператор
        </p>
      </div>
      <div class="termin">
        <p>Персональные данные</p>
        <p>
          любая информация, относящаяся прямо или косвенно к определенному или
          определяемому Субъекту
        </p>
      </div>
      <div class="termin">
        <p>Обработка Персональных данных (Обработка)</p>
        <p>
          любое действие (операция) или совокупность действий (операций) с
          Персональными данными, совершаемых с использованием средств
          автоматизации или без их использования. Обработка включает в себя, в
          том числе: сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование,
          передачу, обезличивание, блокирование, удаление, уничтожение
        </p>
      </div>
      <ul>
        <li><h2>1. Общие положения</h2></li>
        <li>
          <ul>
            <li>
              1.1. Политика является локальным документом Оператора,
              определяющим порядок Обработки и защиты Персональных данных
              Субъектов.
            </li>
            <li>
              1.2 Оператор разработал Политику в соответствии с Конституцией РФ,
              Федеральным законом от 27.07.2006 г. №152-ФЗ «О персональных
              данных», другими нормативными правовыми актами, определяющими
              порядок работы с персональными данными и требованиями к
              обеспечению их безопасности.
            </li>
            <li>
              1.3 Назначением Политики является:
              <ul>
                <li>
                  1.3.1 обеспечение защиты прав и свобод человека и гражданина
                  при обработке его Персональных данных, в том числе защиты прав
                  на неприкосновенность частной жизни, личную и семейную тайну;
                </li>
                <li>
                  1.3.2 четкое и неукоснительное соблюдение требований
                  законодательства Российской Федерации в области персональных
                  данных;
                </li>
                <li>
                  1.3.3 исключение несанкционированных действий любых третьих
                  лиц по Обработке;
                </li>
                <li>
                  1.3.4 предотвращение возникновения возможной угрозы
                  безопасности Субъектов.
                </li>
              </ul>
            </li>
            <li>
              1.4 Субъект выражает свое согласие с условиями Политики при
              использовании Сайта и направлении своих Персональных данных
              Оператору.
            </li>
            <li>
              1.5 Оператор обрабатывает Персональные данные с целью исполнения
              условий Публичной оферты по адресу
              <router-link to="oferta"
                >http://scamdoff.store/oferta</router-link
              >
            </li>
            <li>
              1.6 Оператор опубликовал Политику на Сайте. Оператор предоставляет
              Политику любому лично обратившемуся лицу.
            </li>
            <li>
              1.7 Оператор вправе вносить изменения в Политику. Новая редакция
              Политики вступает в силу с момента ее публикации. Продолжая
              использовать Сайт, Субъект выражает свое согласие с изменениями.
            </li>
          </ul>
        </li>
        <li><h2>2. Принципы Обработки</h2></li>
        <li>
          <ul>
            <li>
              2.1 Оператор обрабатывает Персональные данные на основе следующих
              принципов:
              <ul>
                <li>2.1.1 законная и справедливая основа Обработки;</li>
                <li>
                  2.1.2 Обработка в соответствии с конкретными, заранее
                  определенными и законными целями;
                </li>
                <li>
                  2.1.3 недопущение объединения баз данных, содержащих
                  Персональные данные, Обработка которых осуществляется в целях,
                  несовместимых между собой;
                </li>
                <li>
                  2.1.4 соответствие содержания и объема Персональных данных
                  заявленным целям Обработки;
                </li>
                <li>
                  2.1.5 точность, достаточность, актуальность и достоверность
                  Персональных данных;
                </li>
                <li>
                  2.1.6 законность технических мер, направленных на Обработку;
                </li>
                <li>2.1.7 разумность и целесообразность Обработки;</li>
                <li>
                  2.1.8 хранение Персональных данных не дольше, чем этого
                  требуют цели Обработки, если срок хранения не установлен
                  Законом, договором, стороной которого является Субъект;
                </li>
                <li>
                  2.1.9 уничтожение или обезличивание Персональных данных по
                  достижении целей Обработки или в случае утраты необходимости в
                  их достижении, если иное не предусмотрено Законом.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><h2>3. Перечень обрабатываемых Персональных данных</h2></li>
        <li>
          <ul>
            <li>
              3.1 Персональные данные Субъекта: имя, фамилия, отчество, адрес
              электронной почты, игровой ID, банковские реквизиты.
            </li>
            <li>
              3.2 Иные данные об использовании Сайта, в том числе:
              <ul>
                <li>
                  3.2.1 данные о технических средствах (устройствах): IP-адрес,
                  вид операционной системы, тип браузера, географическое
                  положение, поставщик услуг сети Интернет.
                </li>
                <li>
                  3.2.2 сведения о поведении Субъекта на Сайте (в том числе
                  дата, время и количество посещений, сведения о посещенных
                  страницах, о переходе с других ресурсов).
                </li>
                <li>
                  3.2.3 информация, автоматически получаемая при доступе к
                  Сайту, в том числе с использованием файлов cookies. Файлы
                  cookies представляют собой фрагменты текста, который
                  автоматически сохраняется в память интернет-браузера Субъекта.
                  Это позволяет Сайту в необходимых случаях обращаться к
                  сохраненной информации на компьютере Субъекта и извлекать ее.
                  Вы вправе изменить настройки своего интернет-браузера и
                  отказаться от сохранения файлов cookies.
                </li>
              </ul>
            </li>
            <li>
              3.3 Оператор не проверяет:
              <ul>
                <li>3.3.1 дееспособность Субъекта;</li>
                <li>
                  3.3.2 достоверность представленных Субъектом Персональных
                  данных.
                </li>
              </ul>
            </li>
            <li>
              3.4 Субъект гарантирует:
              <ul>
                <li>
                  3.4.1 предоставленные Персональные данные являются
                  достоверными, актуальными и не нарушают законодательство
                  Российской Федерации;
                </li>
                <li>
                  3.4.2 получение согласия третьего лица на передачу
                  Персональных данных Оператору, последующую Обработку, в
                  случае, если Персональные данные относятся к третьему лицу.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><h2>4. Обработка Персональных данных</h2></li>
        <li>
          <ul>
            <li>
              4.1 Оператор обрабатывает Персональные данные на основе Политики и
              согласия на обработку персональных данных.
            </li>
            <li>
              4.2 Оператор собирает Персональные данные следующими способами:
              <ul>
                <li>
                  4.2.1 Субъект предоставляет данные при заполнении формы на
                  Сайте;
                </li>
                <li>
                  4.2.2 сбор данных о Субъекте с помощью технологий,
                  веб-протоколов, файлов “cookie”, веб-отметок.
                </li>
              </ul>
            </li>
            <li>
              4.3 Оператор хранит Персональные данные:
              <ul>
                <li>
                  4.3.1 исключительно на должным образом защищенных электронных
                  носителях;
                </li>
                <li>
                  4.3.2 с использованием баз данных, находящихся на территории
                  Российской Федерации;
                </li>
                <li>
                  4.3.3 до момента получения требования Субъекта о прекращении
                  Обработки Персональных данных.
                </li>
              </ul>
            </li>
            <li>
              4.4 Оператор вправе передавать Персональные данные следующим
              лицам.
            </li>
            <li>
              4.5 Оператор вправе распространять Персональные данные при
              получении отдельного согласия от Субъекта.
            </li>
            <li>
              4.6 В случае подтверждения факта неточности Персональных данных
              Оператор актуализирует их.
            </li>
            <li>
              4.7 Оператор уничтожает Персональные данные в следующих случаях:
              <ul>
                <li>4.7.1 наличие угрозы безопасности Сайта;</li>
                <li>4.7.2 по запросу Субъекта.</li>
              </ul>
            </li>
            <li>
              4.8 Оператор удаляет всю полученную от Субъекта информацию при
              отзыве им согласия.
            </li>
            <li>
              4.9 Оператор вправе временно прекратить обработку Персональных
              данных, т.е. блокировать Обработку (за исключением случаев, если
              Обработка необходима для уточнения Персональных данных).
            </li>
          </ul>
        </li>
        <li><h2>5. Права Субъекта</h2></li>
        <li>
          <ul>
            <li>
              5.1 Субъект вправе направить Оператору запрос в порядке,
              предусмотренном Политикой, на получение информации об Обработке, в
              том числе содержащей:
              <ul>
                <li>5.1.1 подтверждение факта Обработки;</li>
                <li>5.1.2 правовые основания Обработки;</li>
                <li>5.1.3 цели и применяемые Оператором способы Обработки;</li>
                <li>
                  5.1.4 какие именно Персональные данные обрабатываются и
                  источник их получения;
                </li>
                <li>
                  5.1.5 сроки Обработки, в том числе сроки хранения Персональных
                  данных;
                </li>
                <li>
                  5.1.6 порядок осуществления прав, предусмотренных
                  законодательством Российской Федерации;
                </li>
                <li>
                  5.1.7 информацию об осуществленной или о предполагаемой
                  трансграничной передаче данных;
                </li>
                <li>
                  5.1.8 сведения о лицах, которым могут быть переданы
                  Персональные данные на основании договора с Оператором или в
                  соответствии с законодательством Российской Федерации;
                </li>
                <li>
                  5.1.9 наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего Обработку по поручению Оператора, если
                  Обработка поручена или будет поручена такому лицу;
                </li>
                <li>
                  5.1.10 иные сведения, предусмотренные законодательством
                  Российской Федерации.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><h2>6. Обязанности Оператора</h2></li>
        <li>
          <ul>
            <li>
              6.1 Если Оператор установит факт неправомерной или случайной
              передачи Персональных данных, повлекшей нарушение прав Субъекта,
              Оператор обязан:
              <ul>
                <li>
                  6.1.1 в течение 24 (двадцати четырех) часов с момента
                  инцидента уведомить Роскомнадзор о произошедшем инциденте и о
                  принятых мерах по устранению его последствий, а также
                  предоставить сведения о лице, уполномоченном Оператором на
                  взаимодействие с Роскомнадзором по вопросам, связанным с
                  выявленным инцидентом;
                </li>
                <li>
                  6.1.2 в течение 72 (семидесяти двух) часов с момента инцидента
                  уведомить Роскомнадзор о результатах внутреннего расследования
                  выявленного инцидента, а также предоставить сведения о лицах,
                  действия которых стали причиной выявленного инцидента (при
                  наличии).
                </li>
              </ul>
            </li>
            <li>
              6.2 Оператор обязан:
              <ul>
                <li>
                  6.2.1 по запросу Субъекта предоставлять информацию об
                  Обработке или обоснованный отказ;
                </li>
                <li>
                  6.2.2 принимать меры, необходимые и достаточные для
                  обеспечения выполнения обязанностей, предусмотренных
                  законодательством Российской Федерации.
                </li>
                <li>
                  6.2.3 по требованию Субъекта уточнять обрабатываемые
                  Персональные данные, блокировать или удалять, если они
                  являются неполными, устаревшими, неточными, незаконно
                  полученными или ненужными для заявленной цели Обработки;
                </li>
                <li>
                  6.2.4 обеспечить правомерность Обработки. В случае, если
                  обеспечить правомерность Обработки невозможно, Оператор в
                  срок, не превышающий 10 (десять) рабочих дней с даты выявления
                  неправомерной Обработки, обязан уничтожить Персональные
                  данные;
                </li>
                <li>
                  6.2.5 прекратить Обработку и уничтожить Персональные данные
                  (если их сохранение более не требуется для целей Обработки) в
                  срок, не превышающий 10 (десять) рабочих дней с даты
                  поступления от Субъекта отзыва согласия, за исключением
                  случаев, когда Обработка может быть продолжена в соответствии
                  с законодательством Российской Федерации.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li><h2>7. Меры по обеспечению защиты Персональных данных</h2></li>
        <li>
          <ul>
            <li>
              7.1 Оператор обеспечивает реализацию правовых, организационных и
              технических мер, необходимых и достаточных для обеспечения защиты
              Персональных данных.
            </li>
            <li>
              7.2 Правовые меры включают:
              <ul>
                <li>
                  7.2.1 разработку Оператором локальных документов, реализующих
                  требования российского законодательства, в том числе –
                  Политики и размещение ее на Сайте;
                </li>
                <li>
                  7.2.2 отказ от любых способов Обработки, не соответствующих
                  целям, заранее определенным Оператором.
                </li>
              </ul>
            </li>
            <li>
              7.3 Организационные меры включают:
              <ul>
                <li>
                  7.3.1 назначение лица, ответственного за организацию
                  Обработки;
                </li>
                <li>
                  7.3.2 ограничение состава работников Оператора, имеющих доступ
                  к Персональным данным, и организацию разрешительной системы
                  доступа к ним;
                </li>
                <li>
                  7.3.3 инструктаж и ознакомление работников Оператора,
                  осуществляющих Обработку, с положениями законодательства
                  Российской Федерации о персональных данных, в том числе с
                  требованиями к защите персональных данных, с локальными актами
                  Оператора, регламентирующими порядок работы и защиты
                  персональных данных;
                </li>
                <li>
                  7.3.4 периодическая оценка рисков, касающихся процесса
                  Обработки;
                </li>
                <li>
                  7.3.5 проведение периодических проверок в целях осуществления
                  внутреннего контроля соответствия Обработки требованиям
                  законодательства Российской Федерации.
                </li>
              </ul>
            </li>
            <li>
              7.4 Технические меры:
              <ul>
                <li>
                  7.4.1 предотвращение, в том числе путем проведения внутренних
                  расследований, несанкционированного доступа к системам, в
                  которых хранятся Персональные данные;
                </li>
                <li>
                  7.4.2 резервирование и восстановление Персональных данных,
                  работоспособности технических средств и программного
                  обеспечения, средств защиты информации в информационных
                  системах Персональных данных;
                </li>
                <li>7.4.3 иные необходимые меры безопасности.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li><h2>8. Ограничение действия Политики</h2></li>
        <li>
          <ul>
            <li>
              8.1 Действие Политики распространяется исключительно на Сайт и не
              применяется к другим приложениям или интернет-ресурсам.
            </li>
            <li>
              8.2 Оператор не несет ответственность за действия третьих лиц,
              получивших доступ к Персональным данным по вине Субъекта.
            </li>
          </ul>
        </li>
        <li><h2>9. Обращения Субъекта</h2></li>
        <li>
          <ul>
            <li>
              9.1 Субъект или его представитель вправе направить Оператору свои
              обращения, в том числе отозвать согласие на Обработку, в форме
              электронного документа по адресу электронной почты:
              <a href="mailto:info@scamdoff.com">info@scamdoff.com</a>
            </li>
            <li>
              9.2 Оператор обязуется рассмотреть и направить ответ на
              поступившее обращение в течение 10 (десяти) рабочих дней с момента
              поступления обращения.
            </li>
            <li>
              9.3 Вся корреспонденция, полученная Оператором (обращения в
              письменной или электронной форме), относится к информации
              ограниченного доступа и не разглашается без письменного согласия
              Субъекта.
            </li>
          </ul>
        </li>
      </ul>
      <h2>Реквизиты:</h2>
      <p>ИП: Маммаев Дауд Бадруншахович</p>
      <p>ИНН: 616307082198</p>
      <p>
        Адрес: 350051, Краснодарский край, г. Краснодар, Фестивальный м-н,
        ул.Рашпилевская, дом 335 кв.3
      </p>
      <p>E-mail: <a href="mailto:info@scamdoff.com">info@scamdoff.com</a></p>
    </div>
  </div>
</template>
<script>
import ButtonBack from "@/components/ButtonBack.vue";
import AuthLogo from "@/components/simple/AuthLogo.vue";
export default {
  name: "PolicyPage",
  components: {
    ButtonBack,
    AuthLogo,
  },
};
</script>
<style lang="scss" scoped>
.policy {
  width: 95%;
  max-width: 1110px;
  height: 784px;
  position: relative;
  margin: 105px auto 30px;
  border-radius: 8px;
  border: 1px solid #6a7bfd;
  background-color: rgba(#000000, 0.3);
  padding: 130px 10px 26px 10px;

  @media screen and (max-width: 1800px) {
    position: static;
    transform: none;
    margin: 105px auto 30px;
  }
  @media screen and (max-width: 768px) {
    width: 355px;
  }
  @media screen and (max-width: 374px) {
    width: 95%;
  }
  &-content {
    height: 628px;
    overflow-y: scroll;
    padding: 0px 85px 69px 85px;
    @media screen and (max-width: 768px) {
      height: 100%;
      padding: 0px 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#6a7bfd, 0.1);
      border-radius: 5px;
    }
    label {
      font-size: 12px;
      line-height: 24px;
      margin-bottom: 20px;
      color: var(--text-p-gray);
      display: block;
      margin-bottom: 40px;
    }
    h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 5px;
      color: var(--text-main);
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 20px;
      color: var(--text-main);
    }
    ul {
      ul {
        padding-left: 30px;
        margin-bottom: 40px;
        li {
          margin-bottom: 10px;
        }
      }
      li {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        color: var(--text-p-gray);
      }
    }
    p,
    a {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
      color: var(--text-p-gray);
    }
    a {
      text-decoration: underline;
    }
    p.pl {
      padding-left: 30px;
    }
    .mb-40 {
      margin-bottom: 40px;
    }
    .termin {
      display: flex;
      gap: 10px;
      p:first-child {
        min-width: 120px;
        width: 120px;
      }
    }
  }
}
.auth-logo {
  position: absolute;
  top: -82px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1800px) {
    top: 30px;
  }
}
</style>
