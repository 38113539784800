import axios from "axios";
export default {
  state: {},
  mutations: {},
  actions: {
    umoney(state, data) {
      return axios
        .create({
          headers: {
            "Content-Type": "application/json",
          },
        })
        .post("Payment/BuyCurrency", data)
        .then((data) => {
          window.open(data.data.message, "_blank");
          return {
            success: true,
          };
        })
        .catch(() => {
          return {
            success: false,
            message: "Что-то пошло не так. Попробуйте позже.",
          };
        });
    },
    checkStatusPay(state, accountId) {
      return axios
        .create({
          headers: {
            "Content-Type": "application/json",
          },
        })
        .post(`Payment/AddCurrency?personalId=${accountId}`)
        .then(() => {
          return {
            success: true,
            type: "success",
            message: "Платеж выполнен",
          };
        })
        .catch((error) => {
          if (error.response.status == 400) {
            return {
              success: false,
              type: "info",
              message: "Платежей в очереди нет",
            };
          }
          return {
            success: false,
            type: "error",
            message: "Что-то пошло не так. Попробуйте позже.",
          };
        });
    },
  },
};
