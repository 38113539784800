<template>
  <div class="email">
    <div class="label">scamdoff.store</div>
    <button class="cross" @click="$emit('close')">
      <img loading="lazy" src="@/assets/images/cross.svg" alt="cross" />
    </button>
    <div class="email-form">
      <h2>Оплата</h2>
      <h3>{{ data.money }} ₽</h3>
      <label>Укажите свой E-mail для отправки чека</label>
      <Input
        :model-value="email"
        placeholder="E-mail"
        :is-cross="false"
        :is-error="isError"
        error-msg="Неверный формат e-mail"
        @update:modelValue="(mail) => handleChangeEmail(mail)"
        @blur="validateEmail"
      />
      <Checkbox v-model="agreed">
        <template #label>
          <span class="checkbox-label">
            Я согласен с положениями
            <router-link to="oferta"> Публичной оферты</router-link>
            и&nbsp;<router-link to="policy"
              >Политика&nbsp;конфиденциальности</router-link
            >
          </span>
        </template>
      </Checkbox>
      <div class="email-form-pays">
        <p>Выберите способ оплаты</p>
        <!-- <button class="email-form-pays-pay" :disabled="true">
          <img loading="lazy" src="@/assets/images/qiwi.svg" alt="qiwi" />
        </button> -->
        <button
          class="email-form-pays-pay"
          :disabled="!agreed"
          @click="handleAddEamil"
        >
          <img loading="lazy" src="@/assets/images/u_money.png" alt="u_money" />
        </button>
        <!-- <button class="email-form-pays-pay" :disabled="true">
          <img loading="lazy" src="@/assets/images/paypalych.png" alt="paypalych" />
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
import Input from "../simple/Input.vue";
import Checkbox from "../simple/Checkbox.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { push } from "notivue";

export default {
  name: "EmailModal",
  components: {
    Input,
    Checkbox,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isError: false,
      agreed: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapState({
      accountId: (state) => state.login.accountId,
      email: (state) => state.email.email,
      isAdd: (state) => state.email.isAdd,
    }),
  },
  mounted() {
    this.getEmail(this.accountId);
  },
  methods: {
    ...mapMutations(["setEmail"]),
    ...mapActions(["addEmail", "getEmail", "umoney", "updateEmail"]),
    validateEmail() {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;//eslint-disable-line
      this.isError = !regex.test(this.email);
    },
    handleAddEamil() {
      if (!this.isError && this.email.length) {
        if (this.isEdit) {
          this.updateEmail(this.email);
        }
        this.umoney({
          personalId: this.accountId,
          hardCurrency: this.data.hard,
          email: this.email,
        }).then((response) => {
          if (!response.success) {
            push.error(response.message);
          }
        });
      }
    },
    handleChangeEmail(mail) {
      this.setEmail(mail);
      this.isEdit = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.email {
  position: relative;
  width: 660px;
  background-image: url("@/assets/images/bg_email.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 50px 0px #00000080;
  border-radius: 20px;
  padding: 50px;
  @media screen and (max-width: 1800px) {
    padding: 30px;
    max-height: 95vh;
    overflow-y: scroll;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 66px 30px 30px;
    max-height: none;
    overflow-y: visible;
  }
  @media screen and (max-width: 374px) {
    max-height: 95vh;
    overflow-y: scroll;
  }
  .input {
    margin-bottom: 10px;
  }
  .label {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 16px;
    line-height: 16px;
    color: var(--text-main);
    opacity: 0.5;
  }
  .cross {
    position: absolute;
    top: 28px;
    right: 28px;
    opacity: 0.3;
    @media screen and (min-width: 768px) {
      &:hover {
        opacity: 1;
      }
    }
    img {
      width: 12px;
      height: 12px;
    }
  }
  &-form {
    width: 380px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-main);
    @media screen and (max-width: 768px) {
      width: 295px;
    }
    @media screen and (max-width: 374px) {
      width: 100%;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 40px;
      text-transform: uppercase;
      @media screen and (max-width: 1800px) {
        margin-bottom: 30px;
      }
    }
    h3 {
      font-size: 48px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 40px;
      color: var(--btn-bg-main);
      @media screen and (max-width: 1800px) {
        margin-bottom: 20px;
      }
    }
    label {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        text-align: center;
        max-width: 170px;
      }
    }
    .checkbox {
      margin-bottom: 40px;
      @media screen and (max-width: 1800px) {
        margin-bottom: 30px;
      }
    }
    .checkbox-label {
      font-size: 12px;
      line-height: 15px;
      text-align: left;
      a {
        font-size: 12px;
        line-height: 15px;
        text-decoration: underline;
        color: var(--text-hover);
        &.decoration {
          &::before {
            background-color: var(--text-hover);
          }
        }
      }
    }
    &-pays {
      width: 100%;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 20px;
        text-align: center;
      }
      &-pay {
        background-color: rgba(#000, 0.3);
        margin-bottom: 4px;
        border-radius: 4px;
        width: 100%;
        height: 58px;
        @media screen and (min-width: 768px) {
          &:hover {
            background-color: rgba(#000, 0.1);
          }
        }
        &:disabled {
          background-color: rgba(#000, 0.1);
          cursor: auto;
        }
        img {
          height: 24px;
          width: auto;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
