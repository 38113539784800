<template>
  <button class="yellow f-w--500" type="submit" :disabled="isDisabled">
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "ButtonComponent",
  props: {
    isDisabled: {
      type: Boolean,
      default: true,
    },
    text: String,
  },
};
</script>
<style lang="scss" scoped>
.yellow {
  background-color: var(--btn-bg-main);

  color: var(--btn-text-main);
  height: 48px;
  margin-bottom: 30px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  @media screen and (min-width: 768px) {
    &:hover {
      background-color: var(--btn-bg-hover);
    }
  }
  &:disabled {
    background-color: rgba(#ffd645, 0.3);
  }
}
</style>
