<template>
  <ButtonSVG class="back" text="Назад" @clickEmit="$router.push('/home')">
    <template #default>
      <img src="@/assets/images/arrow_back.svg" alt="arrow_back" />
    </template>
  </ButtonSVG>
</template>
<script>
import ButtonSVG from "@/components/simple/ButtonSVG.vue";
export default {
  name: "ButtonBackComponent",
  components: {
    ButtonSVG,
  },
};
</script>
<style lang="scss">
.back {
  position: absolute;
  top: -57px;
  left: 29px;
  @media screen and (max-width: 1800px) {
    top: 56px;
  }
}
</style>
