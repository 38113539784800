<template>
  <div class="bg_main">
    <picture>
      <source media="(min-width: 768px)" srcset="@/assets/images/main_bg.png" />
      <source
        media="(max-width: 768px)"
        srcset="@/assets/images/phone_bg.png 768x"
      />
      <img loading="lazy" src="@/assets/images/main_bg.png" alt="background" />
    </picture>
  </div>
</template>
<style lang="scss">
.bg_main {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
