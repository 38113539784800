import axios from "axios";
export default {
  state: {
    accountId: null,
    userName: null,
  },
  mutations: {
    setAccountId(state, accountId) {
      state.accountId = accountId;
      localStorage.setItem("accountId", accountId);
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
  },
  actions: {
    login({ commit }, accountId) {
      return axios
        .create({
          headers: {
            "Content-Type": "application/json",
          },
        })
        .post("User/Login", {
          personalId: accountId,
        })
        .then(() => {
          commit("setAccountId", accountId);
          return {
            success: true,
          };
        })
        .catch((error) => {
          if (error.response.data.message == "Error_UserNotFound") {
            return {
              success: false,
              message: "Аккаунт не найден. Проверьте ID и повторите поиск.",
            };
          }
          return {
            success: false,
            message: "Что-то пошло не так. Попробуйте позже.",
          };
        });
    },
    getUserName({ commit }, accountId) {
      return axios
        .create({
          headers: {
            "Content-Type": "application/json",
          },
        })
        .get(`User/GetStoreUserName?PersonalId=${accountId}`)
        .then((data) => {
          commit("setUserName", data.data.value?.userName || "");
          return {
            success: true,
          };
        });
    },
  },
};
