export default function auth({ next, store }) {
  const localAccountId = localStorage.getItem("accountId");
  if (!store.state.login?.accountId && !localAccountId) {
    return next({
      name: "Login",
    });
  }
  if (!store.state.login?.accountId) {
    store.commit("setAccountId", localAccountId);
  }
  return next();
}
